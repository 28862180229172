import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiService from "../../core/apiService";
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import Select from 'react-select';
import {
  dropDownOption, perdropDownOption
} from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { TimePicker } from "antd";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";

const FoodUpdate = (props) => {
  const restaurant = useSelector(state => state.restaurant);
  const [Step, setStep] = useState(0);
  const [createdSuccess, setcreatedSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/happy-hour/food";
  const createPage = location.state?.from?.pathname || "/happy-hour/food";
  const [dayList, setDayList] = useState([]);
  const [foodCatList, setFoodCatList] = useState([]);
  const [days, setDay] = useState("");
  const [starttime, setStarttime] = useState("");
  const [endtime, setEndtime] = useState("");
  const [starttime1, setStarttime1] = useState("");
  const [endtime1, setEndtime1] = useState("");
  const [allday, setAllDay] = useState("");
  const [dayError, setDayError] = useState("");
  const [tags, setTags] = useState([]);
  const [foodCat, setFoodCat] = useState("");
  const [foodCatError, setFoodCatError] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountTypeError, setDiscountTypeError] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [discountPriceError, setDiscountPriceError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [restaurantspecialData, setrestaurantspecialData] = useState("");
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [selectedTag, setselectedTag] = useState([])
  const [selectedfood, setselectedFood] = useState([])
  const [food, setFood] = useState([]);
  const special_id = '3';
  const type_id = '1';
  const restaurantspecial_id = id;
  const [resTag, setResTags] = useState("[]")
  const [tagid, setResTagid] = useState("[]")
  const Priceoption = dropDownOption();
  const perPriceoption = perdropDownOption()
  const [hourerror, setHourerror] = useState("");
  const [starterror, setStarterror] = useState("")
  const [standaradError, setstandandError] = useState("");

  console.log(allday, 'allday')
  const nextStep = () => {
    // Step 0: Check if a day is selected
    if (Step === 0 && days === '') {
      setDayError("Select one option!");
      return;
    }
  
    // Step 1: Happy Time Validations
    if (Step === 1) {
      // Ensure at least one happy time is selected (unless allday is checked)
      if (starttime === '' && endtime === '' && allday === 0) {
        setHourerror("Select at least one happy time!");
        return;
      }
  
      // Ensure both starttime and endtime are selected together
      if (starttime !== "" && endtime === "" && allday === 0) {
        setHourerror("Select end time for happy hour!");
        return;
      }
      if (starttime === "" && endtime !== "" && allday === 0) {
        setHourerror("Select start time for happy hour!");
        return;
      }
      if (allday === 1 && (starttime !== "" || endtime !== "" || starttime1 !== "" || endtime1 !== "")) {
        setHourerror("If 'All Day' is selected, no specific time should be chosen.");
        return;
      }
      if (starttime1 && !endtime1) {
        setHourerror("Please select an End Time for Second Happy Hour.");
        return;
      }
      if (!starttime1 && endtime1) {
        setHourerror("Please select a Start Time for Second Happy Hour.");
        return;
      }
  
      // Validate that starttime is before endtime for both happy hours
      if (starttime !== "" && endtime !== "" && dayjs(starttime, "h:mm A").isAfter(dayjs(endtime, "h:mm A"))) {
        setStarterror("Start time should be less than End time");
        return;
      }
      if (starttime1 !== "" && endtime1 !== "" && dayjs(starttime1, "h:mm A").isAfter(dayjs(endtime1, "h:mm A"))) {
        setStarterror("Start time should be less than End time");
        return;
      }
  
      // Handle case where both start and end times are the same
      if ((starttime !== "" && endtime !== "" && dayjs(starttime, "h:mm A").isSame(dayjs(endtime, "h:mm A"))) ||
          (starttime1 !== "" && endtime1 !== "" && dayjs(starttime1, "h:mm A").isSame(dayjs(endtime1, "h:mm A")))) {
        setStarterror("Start time should be less than End time");
        return;
      }
    }
  
    // Move to the next step if all validations pass
    setStep(Step + 1);
  };
  
  const backStep = () => {
    setStep(Step - 1);
  };

  useEffect(() => {
    if (foodCat >= 0) {
      setselectedFood([])
    }
  }, [foodCat])

  console.log(selectedfood, 'hjjhfkjdf')
  const handleUpdate = () => {
    setIsFormTouched(true);
    if (!description) return;

    let newStarttime = ""
    let newEndtime = ""
    newStarttime = `${starttime}:00`

    if (endtime == "00:00") {
      newEndtime = "23:59:00"
    }

    else {
      newEndtime = `${endtime}:00`
    }
    if (allday == 1) {
      newEndtime = "23:59:00"
    }
    if (foodCat == 0 && selectedfood.length < 1) return setstandandError('Standrad Tag is required!');

    let food_ids = {}
    if (selectedfood.length == 0) {
      food_ids = { "foodcategory_ids": JSON.stringify([foodCat]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    } else
      food_ids = { "foodcategory_ids": JSON.stringify([...selectedfood.map(item => item.value)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    console.log(food_ids, "chekci")


    const data = {
      'restaurant_id': restaurant?.id,
      'special_id': special_id,
      'type_id': type_id,
      'day_id': days,
      'start_time': starttime,
      'end_time': endtime,
      'start_time2':starttime1,
      'end_time2':endtime1,
      'all_day': allday,
      'food_category_id': foodCat,
      'discount_type': discountType,
      'discount_amount': discountPrice,
      'item_description': description,
      'data1': food_ids
    }
    console.log(data, 'datadat')

    setIsLoading(true)
    apiService.post(`/restaurants/specials/food-update/${restaurantspecial_id}`, data, ({ err, data }) => {
      if (err) {
        console.log(err);
        setIsLoading(false)
      };
      setIsLoading(false)
      setcreatedSuccess(createdSuccess === false ? true : false);
      setTimeout(() => {
        navigate(fromPage, { replace: true });
      }, 3000);
    })

  };

  const back = () => {
    navigate(createPage, { replace: true });
  };

  useEffect(() => {
    apiService.get(`/restaurants/getspecial`, { id: restaurantspecial_id }, ({ err, data }) => {
      if (err) return console.log(err);
      console.log(data.data.restaurantspecialshour?.all_day, 'allldadys')
      // console.log(data.data.restaurantspecialshour,"datadtaatdta")
      setrestaurantspecialData(data.data);
      setDay(data.data.day_id);
      setStarttime(data.data.restaurantspecialshour?.start_time);
      setEndtime(data.data?.restaurantspecialshour?.end_time);
      setStarttime1(data.data.restaurantspecialshour?.start_time2);
      setEndtime1(data.data?.restaurantspecialshour?.end_time2);
      setAllDay(data.data.restaurantspecialshour?.all_day);
      setFoodCat(data.data.food_category_id);
      setDiscountType(data.data.discount_type);
      setDiscountPrice(data.data.discount_amount);
      setDescription(data.data.item_description);
      setResTags(data.data.restauranttag.foodcategory_ids)
      setResTagid(data.data.restauranttag.tags_ids)
    })

    apiService.get(`/restaurants/get-days`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setDayList(data.data);
    });
    apiService.get(`/restaurants/get-foodcategory`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      // setFoodCatList(data.data);

      let temp = data.data.map((food) => ({
        value: food.id,
        label: food.foodcategory_name,
      }))
      setFoodCatList([{ value: 0, label: "Multiple Items" }, ...temp,]);
      let res = []
      data.data?.map((item) => {
        console.log(item, 'itemmms1')
        const temp = {};
        temp["label"] = `${item["foodcategory_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      console.log(res, 'resss1we')
      setFood(res);
      console.log(food, 'foods')

    });

    apiService.get(`/listTags`, {}, ({ err, data }) => {
      console.log(data.data, 'tags')
      if (err) return console.log(err);
      let res = [];
      data.data?.map((item) => {
        const temp = {};
        temp["label"] = `${item["tag_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      setTags(res);
    });

  }, []);


  console.log(starttime, "starttime")

  useEffect(() => {
    let res1 = []
    console.log(resTag, food)
    JSON.parse(resTag).map(item => {

      res1.push(...food?.filter(foodid => foodid.value == item))
    });

    setselectedFood(res1);

    let res = []
    console.log(tagid, 'tagid')
    JSON.parse(tagid).map(item => {
      console.log(tagid, item, 'in map')
      res.push(...tags?.filter(tagid => tagid.value == item))
    });
    console.log(res, "res1res1res1")
    setselectedTag(res);
  }, [resTag, food.length, tagid, tags.length])


  const handleOnchangeSpecial = (val) => {
    console.log(val, 'val1')
    setselectedFood(val);

  };

  const handleOnchangeSpecials = (val) => {
    console.log(val, 'valueses')
    setselectedTag(val);

  };
    useEffect(() => {
      // Hide the default "Now" button via CSS
      const style = document.createElement("style");
      style.innerHTML = `
        .ant-picker-now {
          display: none !important;
        }   
      `;
      document.head.appendChild(style);
  
      return () => {
        document.head.removeChild(style);
      };
    }, []);
  
    const renderCloseButton = (setTime) => (
      <button
        className="custom-close-button"
        onClick={() => {
          // Set "Close" when the button is clicked
          setTime("Close");
          document.activeElement?.blur(); // Close picker
        }}
      >
        Close
      </button>
    );
    const renderCloseButtons = (setTime) => (
      <button
        className="custom-close-button"
        onClick={() => {
          // Set "Close" when the button is clicked
          setTime("Close");
          document.activeElement?.blur(); // Close picker
        }}
      >
        Close
      </button>
    );
  return (
    <>
      <div className="dashboard-auto-wrapper">
        <div className="create-from">
          {Step === 0 && (
            <div className="create-from-steps active">
              <h4>What day is your Special? </h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-3">
                  <div className="d-flex flex-row justify-content-between">
                    {dayList.length ? (
                      dayList.map((day, i) =>
                      (
                        <label className="custom-check">
                          {day.day_name}
                          <input
                            type="radio"
                            name="day"
                            key={days === day.id ? days : ''}
                            defaultChecked={days === day.id ? days : ''}
                            onChange={(e) => setDay(day.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>

                  <span className="error">{dayError}</span>
                </div>
              </div>
              <div className="create-from-btn">
                <button className="btn btn-transparent" onClick={back}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={nextStep}>
                  Next
                </button>
              </div>
              <div className="mb-3 d-flex align-items-center  graph-sets mt-5">
              <p className="fs-6">
                  {" "}
                  <b>Note:</b> Choose the days your special is available by
                  checking the boxes above. You can select one or multiple days.
                </p>
              </div>
            </div>
          )}
          {Step === 1 &&
            <div className="create-from-steps active">
              <h4>Set your Happy Hour start and end times</h4>
              <div className="create-form-inner mt-4">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">Start Time</label>
                      <div className="input-right-icon">
                        {/* <input className="form-control"  type="time" name="starttime"  value={starttime} onChange={(e) => setStarttime(e.target.value)}   /> */}
                        <TimePicker
                          className="form-control"
                          size="large"
                          name="starttime"
                          format="h:mm A"
                          // defaultValue={dayjs('13:30:56', 'HH:mm:ss')} 
                          defaultValue={starttime? dayjs(starttime,'h:mm A' ):""}
                          minuteStep={30}


                          onChange={(value, dateString) => {

                            setStarttime(dateString);
                          if (dateString) {
                              setHourerror("");
                              setStarterror("");
                            }	
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">End Time</label>
                      <div className={endtime === "Close" ? "input-right-icon bold_placeholder" : "input-right-icon"} >
                        <TimePicker
                          className="form-control"
                          size="large"
                          onChange={(value, dateString) => {
                            console.log("Time", value, dateString);
                            setEndtime(dateString);
                            if (dateString) {
                              setHourerror("");
                              setStarterror("");
                            }
                          }}
                          value={endtime && endtime !== "Close" ? dayjs(endtime, "h:mm A") : null}
                          format="h:mm A"
                          minuteStep={30}
                          renderExtraFooter={() => renderCloseButtons(setEndtime)}
                          placeholder={endtime === "Close" ? "Close" : "Select Time"}
                          inputReadOnly={true} // Make the input read-only so the user cannot type anything
                        />
                        {endtime === "Close"}
                      </div>
                    </div>
                  </div>
                </div>

                <h4>Second Happy Hour (Optional)</h4>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">Start Time</label>
                      <div className="input-right-icon">
                        {/* <input className="form-control"  type="time" name="starttime"  value={starttime} onChange={(e) => setStarttime(e.target.value)}   /> */}
                        <TimePicker
                          className="form-control"
                          size="large"
                          name="starttime"
                          format="h:mm A"
                          // defaultValue={dayjs(`2022-04-17T${starttime}:00`)}
                          defaultValue={starttime1? dayjs(starttime1,'h:mm A' ):""}

                          minuteStep={30}


                          onChange={(value, dateString) => {

                            setStarttime1(dateString);
                 if (dateString) {
                              setHourerror("");
                              setStarterror("");
                            }	
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">End Time</label>
                        <div className={endtime1 === "Close" ? "input-right-icon bold_placeholder" : "input-right-icon"} >
                                          <TimePicker
                                            className="form-control"
                                            size="large"
                                            onChange={(value, dateString) => setEndtime1(dateString)}
                                            value={endtime1 && endtime1 !== "Close" ? dayjs(endtime1, "h:mm A") : null}
                                            format="h:mm A"
                                            minuteStep={30}
                                            renderExtraFooter={() => renderCloseButton(setEndtime1)}
                                            placeholder={endtime1 === "Close" ? "Close" : "Select Time"}
                                            inputReadOnly={true} // Make the input read-only so the user cannot type anything
                                          />
                                          {endtime1 === "Close"}
                                        </div>
                    </div>
                  </div>
                </div>



                
                <div className="form-group d-flex align-items-center flex-row mb-4">
                  <label className="custom-radio mb-3">All Day
                    <input type="radio" name="allday" onClick={() =>
                    {
                    setAllDay(prev => prev == 1 ? 0 : 1);
                    if(allday){
                      setStarterror("");
                      setHourerror("");

                    }                
                    }
                    }
                    
                    // defaultChecked={setAllDay(allday == 1 ? true : false)}
                      checked={allday == 1 ? true : false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <span className="error">{hourerror}</span>
                <span className="error">{starterror}</span>
              </div>
              <div className="create-from-btn">
                <button className="btn btn-transparent" onClick={backStep}>Back</button>
                <button className="btn btn-dark" onClick={nextStep}>Next</button>
              </div>
              <div className="mb-3 d-flex align-items-center  graph-sets mt-3">

                {/* <p> <b>Note:</b> This is a 24 Hour clock format (Example- 3 PM = 15:00 Hours)</p> */}
              </div>
            </div>
          }
          {Step === 2 && (
            <div className="create-from-steps active">
              <h4>Update your Food Special</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Food Category</label>
                  <Select
                    classNamePrefix="form-control"

                    onChange={(selectedOption) => setFoodCat(selectedOption.value)}
                    options={foodCatList}
                    value={foodCatList.filter(function (option) {
                      return option.value === foodCat
                    })}
                  />
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>

                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Discount</label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === 'percentage' ? 'percentage' : ''}
                        defaultChecked={discountType === 'percentage' ? 'percentage' : ''}
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === 'price' ? 'price' : ''}
                        defaultChecked={discountType === 'price' ? 'price' : ''}
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === 'amount' ? 'amount' : ''}
                        defaultChecked={discountType === 'amount' ? 'amount' : ''}
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Food item description(255 characters)</label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  ></textarea>
                  {
                    (isFormTouched && !description) && <span className="error">Description is required!</span>
                  }
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <div className="form-group">
                  <div className="input-right-icon">
                    <label className="form-label">Special Tags</label>
                    <Multiselect
                      placeholder="Select Special Tags"
                      options={tags}
                      displayValue="label"
                      selectedValues={selectedTag}
                      onSelect={handleOnchangeSpecials}
                      onRemove={(selectedList, removedItem) => {
                        setselectedTag(selectedList)
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center  graph-sets mt-2">

                  <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-right-icon">
                    <label className="form-label">Standard Tags</label>
                    <Multiselect
                      placeholder="Select Food Tags"
                      options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                      onSelect={handleOnchangeSpecial}
                      disablePreSelectedValues={!!food.filter((item => item.value == foodCat)).length}
                      displayValue="label"
                      selectedValues={foodCat !== 0 ? foodCatList.filter(function (option) {
                        return option.value === foodCat
                      }) : selectedfood}
                      onRemove={(selectedList, removedItem) => {
                        setselectedFood(selectedList)
                      }}
                    />
                  </div>
                  <span className="error">{standaradError}</span>

                </div>
                <div className="d-flex align-items-center  graph-sets mt-2">
                  <p> <b>Note:</b> If ‘Multiple Items’ is selected for Food Category, select all tags that apply.</p>
                </div>
              </div>

              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={isLoading} onClick={handleUpdate}>
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

                  }
                </button>
              </div>
            </div>
          )}

          {createdSuccess === true ? (
            <>
              <div className="common-message-pop show" id="created-success-pop">
                <div className="common-pop-inner">
                  <div className="common-pop-box created-message-box">
                    <h2>Cheers!</h2>
                    <p className="message-txt"> You just updated a Happy Hours Special!!</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default FoodUpdate;
