import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiService from "../../core/apiService";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faClock } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import {
  dropDownOption, perdropDownOption
} from "../../helpers";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { TimePicker } from "antd";
import moment from "moment";
import CustomError from "../../components/CustomError";
import dayjs from "dayjs";
// import { Space, TimePicker } from 'antd';
<TimePicker size="large" />

const FoodCreate = () => {
  const restaurant = useSelector(state => state.restaurant);
  const [Step, setStep] = useState(0);
  const [createdSuccess, setcreatedSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/happy-hour/food";
  const createPage = location.state?.from?.pathname || "/happy-hour/food";
  const [dayList, setDayList] = useState([]);
  const [foodCatList, setFoodCatList] = useState([]);
  const [day, setDay] = useState("");
  const [dayError, setDayError] = useState("");
  const [starttime, setStarttime] = useState("");
  const [starttime1, setStarttime1] = useState("");
  const [endtime, setEndtime] = useState("");
  const [endtime1, setEndtime1] = useState("");

  const [allday, setAllDay] = useState("");
  const [hourerror, setHourerror] = useState("");
  const [starterror, setStarterror] = useState("")
  const [foodCat, setFoodCat] = useState("");
  const [food, setFood] = useState([]);
  const [special, setSelectedSpecials] = useState([]);
  const [foodCatError, setFoodCatError] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountTypeError, setDiscountTypeError] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [discountPriceError, setDiscountPriceError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [standarad, setSelectedStandrad] = useState([]);
  const special_id = '3';
  const [tags, setTags] = useState([]);
  const type_id = '1';
  const Priceoption = dropDownOption();
  const perPriceoption = perdropDownOption()

  console.log(starttime, "starttime")

  const nextStep = () => {
    if (Step === 0 && day === '') {
      setDayError("Select one option!")
      return;
    }
     if (Step === 1) {
        // Ensure at least one happy time is selected (unless "All Day" is checked)
        if (!starttime && !endtime && !starttime1 && !endtime1 && !allday) {
          setHourerror("Select at least one happy time!");
          return;
        }
        if (!starttime && !endtime && !allday) {
          setHourerror("Select at least one happy time!");
          return;
        }
           if (allday  && (starttime !== "" || endtime !== "" || starttime1 !== "" || endtime1 !== "")) {
        setHourerror("If 'All Day' is selected, no specific time should be chosen.");
        return;
      }
    
        // Ensure both starttime and endtime are selected together
        if (starttime && !endtime) {
          setHourerror("Please select an End Time.");
          return;
        }
        if (!starttime && endtime) {
          setHourerror("Please select a Start Time.");
          return;
        }
    
        // Ensure both starttime1 and endtime1 are selected together (if either is chosen)
        if (starttime1 && !endtime1) {
          setHourerror("Please select an End Time for Second Happy Hour.");
          return;
        }
        if (!starttime1 && endtime1) {
          setHourerror("Please select a Start Time for Second Happy Hour.");
          return;
        }
    
        // Validate time order for both time slots
        if (starttime && endtime && dayjs(starttime, "h:mm A").isAfter(dayjs(endtime, "h:mm A"))) {
          setStarterror("Start time should be less than End time.");
          return;
        }
         if (starttime && endtime && dayjs(starttime, "h:mm A").isSame(dayjs(endtime, "h:mm A"))) {
                setStarterror("Start time should be less than End time.");
                return;
              }
        if (starttime1 && endtime1 && dayjs(starttime1, "h:mm A").isAfter(dayjs(endtime1, "h:mm A"))) {
          setStarterror("Start time for Second Happy Hour should be less than End time.");
          return;
        }
        if (starttime1 && endtime1 && dayjs(starttime1, "h:mm A").isSame(dayjs(endtime1, "h:mm A"))) {
          setStarterror("Start time for Second Happy Hour should be less than End time.");
          return;
        }
        // if (starttime1 && endtime1 && dayjs(starttime1, "h:mm A").isSame(dayjs(endtime1, "h:mm A"))) {
        //   setStarterror("Start time for Second Happy Hour should be less than End time.");
        //   return;
        // }
        setHourerror("");
        setStarterror("");
      }
    setStep(Step + 1);
  };
  const backStep = () => {
    setStep(Step - 1);
  };
  console.log(foodCat, 'foodCat')

  useEffect(() => {
    // Hide the default "Now" button via CSS
    const style = document.createElement("style");
    style.innerHTML = `
      .ant-picker-now {
        display: none !important;
      }   
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const renderCloseButton = (setTime) => (
    <button
      className="custom-close-button"
      onClick={() => {
        // Set "Close" when the button is clicked
        setTime("Close");
        document.activeElement?.blur(); // Close picker
      }}
    >
      Close
    </button>
  );
  const renderCloseButtons = (setTime) => (
    <button
      className="custom-close-button"
      onClick={() => {
        // Set "Close" when the button is clicked
        setTime("Close");
        document.activeElement?.blur(); // Close picker
      }}
    >
      Close
    </button>
  );
  useEffect(() => {
    if (foodCat > 0) {
      setSelectedStandrad([])
    }
  }, [foodCat])
  const handleSubmit = () => {

    console.log(standarad, 'length')

    console.log(special, 'specialspecial')

    // let food_ids = []
    // if (foodCat == 0) {
    //   food_ids = ((standarad.length >= special.length) ? standarad : special)?.map((item, i) => {
    //     return { "foodcategory_id": standarad[i] ? standarad[i].value : 0,  tags_id: special[i] ? special[i].value : 0 }
    //   })
    // } else {
    //   food_ids = a Special?.map((item, i) => {
    //     return {  tags_id: special[i] ? special[i].value : 0 }

    //   }
    //   )
    //   food_ids[0].foodcategory_id = foodCat
    //   console.log(food_ids, 'food')
    // }
    let food_ids = {}
    if (standarad.length == 0) {
      food_ids = { "foodcategory_ids": JSON.stringify([foodCat]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
    } else
      food_ids = { "foodcategory_ids": JSON.stringify([...standarad.map(item => item.value)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }

    // if (day === '') {
    //   setDayError("Select one option!");
    //   return;
    // }
    // if(starttime === '' && endtime === '' && allday === '') {
    //   setHourerror("Select atleast one happy time!");
    //   return;
    // }
    // if (foodCat === '') {
    //   setFoodCatError("Select one option!");
    //   return;
    // }
    // if (discountType === '') {
    //   setDiscountTypeError("Select one option!");
    //   return;
    // }
    // if (discountPrice === '') {
    //   setDiscountPriceError("Select one option!");
    //   return;
    // }
    // if (description === '') {
    //   setDescriptionError("Select one option!");
    //   return;
    // }

    const data = {
      'restaurant_id': restaurant?.id,
      'special_id': special_id,
      'type_id': type_id,
      'day_id': day,
      'start_time': starttime,
      'end_time': endtime,
      'start_time2':starttime1,
      'end_time2':endtime1,
      'all_day': allday,
      'food_category_id': foodCat,
      'discount_type': discountType,
      'discount_amount': discountPrice,
      'item_description': description,
      'data1': food_ids
    }

    if (discountPrice && description && discountType && foodCat >= 0) {
      setIsLoading(true)
      day.forEach((element) => {
        data.day_id = element;
      apiService.post('/restaurants/specials/create', data, ({ err, data }) => {
        if (err) {
          setIsLoading(false)
          console.log(err);
        };
        setIsLoading(false)
        setcreatedSuccess(createdSuccess === false ? true : false);
        setTimeout(() => {
          navigate(fromPage, { replace: true });
        }, 5000);
      })
      })        
    } else {
      CustomError('Please fill all the required fields', '')
    }
  };


  const back = () => {
    navigate(createPage, { replace: true });
  };

  useEffect(() => {
    apiService.get(`/restaurants/get-days`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setDayList(data.data);
    });
    apiService.get(`/restaurants/get-foodcategory`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      let temp = data?.data?.map((food) => ({
        value: food.id,
        label: food.foodcategory_name,
      }))
      setFoodCatList([{ value: 0, label: "Multiple Items" }, ...temp,]);
      let res = [];
      data.data?.map((item) => {
        console.log(item, 'itemmms1')
        const temp = {};
        temp["label"] = `${item["foodcategory_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      console.log(res, 'resss1')
      setFood(res);

    });
    apiService.get(`/listTags`, {}, ({ err, data }) => {
      console.log(data.data, 'tags')
      if (err) return console.log(err);
      let res = [];
      data.data?.map((item) => {
        const temp = {};
        temp["label"] = `${item["tag_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });

      console.log(res, 'resss')
      setTags(res);
    });
  }, []);
  console.log(foodCat, 'foodCat')


  const handleOnchangeSpecial = (val) => {
    setSelectedStandrad(val);

  };

  const handleOnchangeSpecials = (val) => {
    console.log(val, 'valueses')
    setSelectedSpecials(val);

  };
  const handleDaySelection = (dayId, isChecked) => {
    if (isChecked) {
      setDay((prevDays) => [...prevDays, dayId]); // Add to array if checked
    } else {
      setDay((prevDays) => prevDays.filter((id) => id !== dayId)); // Remove if unchecked
    }
  };
console.log(allday,'allllll')

  return (
    <>
      <div className="dashboard-auto-wrapper">
        <div className="create-from">
          {Step === 0 && (
            <div className="create-from-steps active">
              <h4>What day is your Special?</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-3">
                  <div className="d-flex flex-row justify-content-between">
                  {dayList.length ? (
                      dayList.map((days, i) => (
                        <label className="custom-check" key={days.id}>
                          {days.day_name}
                          <input
                            type="checkbox"
                            id={`day-${days.id}`}
                            value={days.id}
                            checked={day.includes(days.id)} // Check if the day is selected
                            onChange={(event) =>
                              handleDaySelection(days.id, event.target.checked)
                            } // Use the refactored function
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>

                  <span className="error">{dayError}</span>
                </div>
              </div>
              <div className="create-from-btn">
                <button className="btn btn-transparent" onClick={back}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={nextStep}>
                  Next
                </button>
              </div>
              <div className="mb-3 d-flex align-items-center  graph-sets mt-5">
              <p className="fs-6">
                  {" "}
                  <b>Note:</b> Choose the days your special is available by
                  checking the boxes above. You can select one or multiple days.
                </p>
              </div>
            </div>
          )}
          {Step === 1 &&
            <div className="create-from-steps active">
              <h4>Set your Happy Hour start and end times</h4>
              <div className="create-form-inner mt-4">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">Start Time</label>
                      <div className="input-right-icon">
                        {/* <input
                          className="form-control"
                          type="time"
                          name="starttime"
                          onChange={(e) => setStarttime(e.target.value)}
                        /> */}
                        <TimePicker
                          className="form-control"
                          size="large"
                          name="starttime"

                          format="h:mm A"
                          minuteStep={30}
                          defaultValue={starttime? dayjs(starttime,'h:mm A' ):""}

                          onChange={(value, dateString) => {

                            setStarttime(dateString);
                            if (dateString) {
                              setHourerror("");
                              setStarterror("");
                            }	
                          }}
                        />

                        {/* <FontAwesomeIcon icon={faClock} /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">End Time</label>
                      <div className={endtime === "Close" ? "input-right-icon bold_placeholder" : "input-right-icon"} >
                                           <TimePicker
                                             className="form-control"
                                             size="large"
                                             onChange={(value, dateString) => {
                                               console.log("Time", value, dateString);
                                               setEndtime(dateString);
                                               if (dateString) {
                                                 setHourerror("");
                                                 setStarterror("");
                                               }
                                             }}
                                             value={endtime && endtime !== "Close" ? dayjs(endtime, "h:mm A") : null}
                                             format="h:mm A"
                                             minuteStep={30}
                                             renderExtraFooter={() => renderCloseButtons(setEndtime)}
                                             placeholder={endtime === "Close" ? "Close" : "Select Time"}
                                             inputReadOnly={true} // Make the input read-only so the user cannot type anything
                                           />
                                           {endtime === "Close"}
                                         </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className='time-add-more'>
                      <button className='btn btn-dark'>Add More</button>
                    </div>
                  </div> */}

                </div>

            <div>  <h4>Second Happy Hour (Optional)</h4></div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">Start Time</label>
                      <div className="input-right-icon">
                        {/* <input
                          className="form-control"
                          type="time"
                          name="starttime"
                          onChange={(e) => setStarttime(e.target.value)}
                        /> */}
                        <TimePicker
                          className="form-control"
                          size="large"
                          name="starttime"

                          format="h:mm A"
                          minuteStep={30}
                           defaultValue={starttime1? dayjs(starttime1,'h:mm A' ):""}
                          
                          onChange={(value, dateString) => {
                            setStarttime1(dateString);
                           if (dateString) {
                              setHourerror("");
                              setStarterror("");
                            }	
                          }}
                        />

                        {/* <FontAwesomeIcon icon={faClock} /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-4">
                      <label className="form-label">End Time</label>
                      <div className={endtime1 === "Close" ? "input-right-icon bold_placeholder" : "input-right-icon"} >
                                             <TimePicker
                                               className="form-control"
                                               size="large"
                                               onChange={(value, dateString) => {
                                                 console.log("Time", value, dateString);
                                                 setEndtime1(dateString);
                                                 if (dateString) {
                                                   setHourerror("");
                                                   setStarterror("");
                                                 }
                                               }}
                                               value={endtime1 && endtime1 !== "Close" ? dayjs(endtime1, "h:mm A") : null}
                                               format="h:mm A"
                                               minuteStep={30}
                                               renderExtraFooter={() => renderCloseButton(setEndtime1)}
                                               placeholder={endtime1 === "Close" ? "Close" : "Select Time"}
                                               inputReadOnly={true} // Make the input read-only so the user cannot type anything
                                             />
                                             {endtime1 === "Close"}
                                           </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className='time-add-more'>
                      <button className='btn btn-dark'>Add More</button>
                    </div>
                  </div> */}

                </div>


                <div className="form-group d-flex align-items-center flex-row mb-4">
                  <label className="custom-radio mb-3">All Day
                    <input type="radio" name="allday" onChange={(e) =>{ 
                      setAllDay(e.target.value);
                        setHourerror("");
                        setStarterror("");

                      }} 
                      defaultChecked={allday ? true:false}
                      />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <span className="error">{hourerror}</span>
                <span className="error">{starterror}</span>
              </div>
              <div className="create-from-btn">
                <button className="btn btn-transparent" onClick={backStep}>Back</button>
                <button className="btn btn-dark" onClick={nextStep}>Next</button>
              </div>
              <div className="mb-3 d-flex align-items-center  graph-sets mt-3">

                {/* <p> <b>Note:</b> This is a 24 Hour clock format (Example- 3 PM = 15:00 Hours)</p> */}
              </div>
            </div>
          }
          {Step === 2 && (
            <div className="create-from-steps active">
              <h4>Create your Food Special</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Food Category <span className="text-danger">*</span></label>
                  <Select
                    classNamePrefix="form-control"

                    onChange={(selectedOption) => setFoodCat(selectedOption.value)}
                    options={foodCatList}

                  />
                  <span className="error">{foodCatError}</span>
                  <div className="d-flex align-items-center  graph-sets mt-2">
                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Discount <span className="text-danger">*</span></label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Food item description(255 characters) <span className="text-danger">*</span></label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <div className="form-group">
                  <div className="input-right-icon">
                    <label className="form-label">Special Tags</label>
                    <Multiselect
                      placeholder="Select Special Tags"
                      options={tags}
                      displayValue="label"
                      onSelect={handleOnchangeSpecials}
                      onRemove={(selectedList, removedItem) => {
                        setSelectedStandrad(selectedList)
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center  graph-sets mt-2">

                  <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-right-icon">
                    <label className="form-label">Standard Tags</label>
                    <Multiselect
                      placeholder="Select Food Tags"
                      options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                      onSelect={handleOnchangeSpecial}
                      disablePreSelectedValues={!!food.filter((item => item.value == foodCat)).length}
                      displayValue="label"
                      selectedValues={food.filter((item => item.value == foodCat))}
                      onRemove={(selectedList, removedItem) => {
                        setSelectedStandrad(selectedList)
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center  graph-sets mt-2">
                  <p> <b>Note:</b> If ‘Multiple Items’ is selected for Food Category, select all tags that apply.</p>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

                  }</button>
              </div>
            </div>
          )}

          {createdSuccess === true ? (
            <>
              <div className="common-message-pop show" id="created-success-pop">
                <div className="common-pop-inner">
                  <div className="common-pop-box created-message-box">
                    <h2>Cheers!</h2>
                    <p className="message-txt">You just created a Happy Hours special!!</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default FoodCreate;
