import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faCircleNotch, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Filter from '../../assets/images/filter-ico.svg';
import apiService, { REACT_APP_LIMIT } from '../../core/apiService';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getDays, getRegions, dropDownOption, getWineType, getWineSubType, getContainer, getSize } from '../../helpers';
import Select from 'react-select';
import { Button } from 'react-bootstrap';


const HappyHourDrink = () => {
	const location = useLocation();
	const restaurant = useSelector(state => state.restaurant);
	const [isFilter, setisFilter] = useState(false);
	const [days, setDays] = useState([]);
	const [regions, setRegions] = useState([]);
	const [wineTypes, setWineType] = useState([]);
	const [wineSubTypes, setWineSubType] = useState([]);
	const [containers, setContainer] = useState([]);
	const [otherDrinkList, setOtherDrinkList] = useState(null);
	const [sizes, setSize] = useState([]);
	const [appliedFilters, setAppliedFilters] = useState({
		search: '',
		start_time: '',
		end_time: '',
		day_id: '',
		discount_amount: '',
		discount_type: '',
		container_type_id: '',
		size_id: '',
		wine_type_id: '',
		wine_subtype_id: '',
		region_id: ''
	})
	const [dataLoading, setDataLoading] = useState(false);
	const [wineLists, setWineList] = useState(null);
	const [beersLists, setBeerList] = useState(null);
	const [ciderLists, setCiderList] = useState(null);
	const [loader , setLoader] = useState(true)
	const [listError, setListError] = useState();
	const [tabKey, setTabKey] = useState("1");
	const [currentPage, setCurrentPage] = useState(1)
	const limit = process.env.REACT_APP_LIMIT;
	const [total, setTotal] = useState([])
	const searchParams = new URLSearchParams(location.search);
	const query = searchParams.get("type")
	const navigate = useNavigate();
	

	const showFilter = () => {
		setisFilter(prevState => !prevState);

		// If the filter is being opened, fetch data based on the current page
		if (!isFilter) {
			setCurrentPage(currentPage);
		getDays({
			callback: ({ err, data }) => {
				if (err) return console.log(err);
				setDays(data.data);
			}
		});
		getContainer({
			callback: ({ err, data }) => {
				if (err) return console.log(err);
				setContainer(data.data);
			}
		});
		getSize({
			callback: ({ err, data }) => {
				if (err) return console.log(err);
				setSize(data.data);
			}
		});
		if (tabKey !== '1') return;
		getRegions({
			callback: ({ err, data }) => {
				if (err) return console.log(err);
				setRegions(data.data);
			}
		});
	}
		// getWineType({
		// 	callback: ({ err, data }) => {
		// 		if (err) return console.log(err);
		// 		setWineTypes(data.data);
		// 	}
		// });
		// getWineSubType({
		// 	callback: ({ err, data }) => {
		// 		if (err) return console.log(err);
		// 		setWineSubTypes(data.data);
		// 	}
		// })
	};

	// useEffect(()=>{
	
	// 	const searchParams = new URLSearchParams(location.search);
	// 	const query = searchParams.get("type")
	// 	  setTabKey(query || 1);
	//   }, [location.search]);
	
	// console.log(tabKey,"rrrrr")
	const getDrinkList = (args) => {
		console.log(appliedFilters, "kkkkk")
		setDataLoading(true);
		let params = {
			type_id: 2, special_id: 3, restaurant_id: restaurant?.id, drinktype_id: args?.tabKey, pageno: currentPage,
			start_time: appliedFilters.start_time,
			end_time: appliedFilters.end_time,
			day_id: appliedFilters.day_id,
			discount_amount: appliedFilters.discount_amount,
			discount_type: appliedFilters.discount_type,
			container_type_id: appliedFilters.container_type_id,
			size_id: appliedFilters.size_id,
			wine_type_id: appliedFilters.wine_type_id,
			wine_subtype_id: appliedFilters.wine_subtype_id,
			region_id: appliedFilters.region_id

		};
		if (args?.filters) {
			params = { ...params, ...args.filters };
		}
		apiService.get(`/restaurants/specials`, params, ({ err, data }) => {
			let temp = data
			if (err) return setDataLoading(false),
				setListError(err.error), setWineList([]), setBeerList([]), setCiderList([]), setisFilter(false);

			apiService.get(`/listDrinkNames`, { drink_type: tabKey }, ({ err, data }) => {
				console.log(data.data, 'datdatadta')
				let temp1 = data


				console.log(tabKey, 'tabKeytabKey')
				apiService.get(`/listTagss`, { type: tabKey }, ({ err, data }) => {
					if (err) return console.log(err);

					let drinkCategories = []
					let filterTags = []
					let filterWine = []
					let filetrWineName = []
					temp.data.rows.map(item => {

						console.log(item,'item.restauranttag?.drink_ids')
						if (!(item.restauranttag?.drink_ids == null)){ 
							let drinkId = item.restauranttag?.drink_ids
							console.log(drinkId, 'drinkid')
							drinkCategories = temp1.data.filter(food => eval(drinkId).includes(food.id))
								.map(filteredDrink => filteredDrink.drink_name);
							item.Standard_Tags = drinkCategories.join(',')
						}
						if (!(item.restauranttag?.tags_ids == null)){ 
						let tagId = item.restauranttag.tags_ids					
						filterTags = data.data.filter(tag => eval(tagId).includes(tag.id))
							.map(filteredTag => filteredTag.tag_name);
					
						item.Special_tag = filterTags.join(',')
					}
						if (!(item.restauranttag?.wine_type_ids == null)) {
						let winetypeid = item.restauranttag?.wine_type_ids
					
						filterWine = wineTypes.filter(wine => eval(winetypeid).includes(wine.id))
							.map(filetr => filetr.wine_name)
						item.winetypes = filterWine.join(',')
					}
					
						if (!(item.restauranttag?.wine_ids == null)) {
							let winenameid = item.restauranttag?.wine_ids

							filetrWineName = wineSubTypes.filter(wine => eval(winenameid).includes(wine.id))
								.map(filetr => filetr.winesubtype_name)
					   
							item.winenames = filetrWineName.join(',')
	                         }
						

					



					});
					if (tabKey === '1') return setWineList(temp.data.rows);
					if (tabKey === '2') return setBeerList(temp.data.rows);
					if (tabKey === '3') return setCiderList(temp.data.rows);
					if (tabKey === '4') return setOtherDrinkList(temp.data.rows);
					// setSpecialsList(temp.data.rows);
				})
			});
			setTotal(data.data.count)
			setDataLoading(false);
			setisFilter(false);




		})
	}

	useEffect(()=>{

		if(query !== null){
			setTabKey(query)
			setLoader(false)
		}else{
			setLoader(false)
		}
	},[location.search])
	
	
		console.log(tabKey,"aaaaa")
	useEffect(() => {
		getWineType({
			callback: ({ err, data }) => {
				console.log(data.data, 'datdadt')
				if (err) return console.log(err);
				setWineType(data.data);
			}
		});
		getWineSubType({
			callback: ({ err, data }) => {
				if (err) return console.log(err);
				setWineSubType(data.data);
			}
		});
		setDataLoading(true);
		getDrinkList({
			tabKey
		})
		// apiService.get(`/restaurants/specials`, { ...{ type_id: 2, special_id: 1, drinktype_id: tabKey }, restaurant_id: restaurant?.id }, ({ err, data }) => {
		// 	if (err) return setDataLoading(false), setListError(err.error);
		// 	setWineList(data.data);
		// 	setDataLoading(false);
		// })
	}, [ currentPage, wineSubTypes.length, wineTypes.length]);
	const handleSelect = (key) => {
		setTabKey(key);
		setCurrentPage(1)
		// apiService.get(`/restaurants/specials`, { ...{ type_id: 2, special_id: 3, drinktype_id: key }, restaurant_id: restaurant?.id }, ({ err, data }) => {
		// 	if (err) return setDataLoading(false), setListError(err.error);
		// 	if (key === '1') return setWineList(data.data);
		// 	if (key === '2') return setBeerList(data.data);
		// 	if (key === '3') return setCiderList(data.data);
		// 	setDataLoading(false);
		// })
	}
	const updateData = (page) => {
		setCurrentPage(page)
	}

	const lastPage = Math.ceil(total / REACT_APP_LIMIT)

	const nextPage = () => {
		if (currentPage < lastPage) {
			updateData(currentPage + 1)
		}
	}

	const previousPage = () => {
		updateData(currentPage - 1)
	}
	const handleUpdate = (id) => {
		navigate(`/happy-hour/drink/update/${id}`, { replace: true });
	}

	const handleDelete = (id) => {
		apiService.remove(`/restaurants/specials/food/${id}`, ({ err, data }) => {
			if (err) return setDataLoading(false), setListError(err.error);
			setWineList(wineLists?.filter((i) => (i.id !== id)));
			setBeerList(beersLists?.filter((i) => (i.id !== id)));
			setCiderList(ciderLists?.filter((i) => (i.id !== id)));
			setOtherDrinkList(otherDrinkList?.filter((i) => (i.id !== id)));
		});
	}

	const resetFilters = () => {
		setAppliedFilters({
			search: '',
			start_date: '',
			end_date: '',
			end_time:'',
			start_time:'',
			day_id: '',
			discount_amount: '',
			discount_type: '',
			container_type_id: '',
			size_id: '',
			wine_type_id: '',
			wine_subtype_id: '',
			startTime: '',
			endTime: '',
			region_id: ''

		});



		getDrinkList({
			tabKey,
			filters: {
				search: '',
				start_date: '',
				end_date: '',
				day_id: '',
				discount_amount: '',
				discount_type: '',
				container_type_id: '',
				size_id: '',
				wine_type_id: '',
				wine_subtype_id: '',
				startTime: '',
				endTime: '',
				region_id: ''
			}
		});
		setBeerList(null)
		setWineList(null)
		setOtherDrinkList(null)
		setCiderList(null)
	}
	useEffect(() => {

			resetFilters(); // Reset all filters

	}, [tabKey]);

	const submitDelete = (id) => {

		confirmAlert({
			title: 'Confirm to delete',
			message: 'Are you sure you want to delete?.',
			buttons: [
				{
					label: 'Yes',
					onClick: () => handleDelete(id)
				},
				{
					label: 'No',
					//onClick: () => alert('Click No')
				}
			]
		});
	};

	// const resetFilters = () => {
	// 	setAppliedFilters({
	// 		search: '',
	// 		start_time: '',
	// 		end_time: '',
	// 		day_id: '',
	// 		discount_amount: '',
	// 		discount_type: '',
	// 		container_type_id: '',
	// 		size_id: '',
	// 		wine_type_id: '',
	// 		wine_subtype_id: '',
	// 		region_id: ''
	// 	});
	// 	getDrinkList({
	// 		tabKey,
	// 		filters:{
	// 			search: '',
	// 			start_time: '',
	// 			end_time: '',
	// 			day_id: '',
	// 			discount_amount: '',
	// 			discount_type: '',
	// 			container_type_id: '',
	// 			size_id: '',
	// 			wine_type_id: '',
	// 			wine_subtype_id: '',
	// 			region_id: ''
	// 		}
	// 	});


	// }

	console.log(wineLists, "wineListswineLists")
	return (
		<>
			<div className="dashboard-top-btn">
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>
				<Link to="/happy-hour/drink/create"><button className="btn btn-dark"><FontAwesomeIcon icon={faPencilAlt} /> Create</button></Link>
			</div>
			<div className="tab-content" id="common-tabs-section">
				{ loader ==false  ?
				<Tabs defaultActiveKey={tabKey}  onSelect={handleSelect} id="daily-special-tabs" className="mb-3">
					<Tab eventKey={1} title="Wine">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
											<th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Region</th>
											<th>Red/White</th>
											<th>Wine Type</th>
											<th>Wine Name</th>
											<th>Container Type</th>
											<th>Size</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Winetype Tags</th>
											<th>Winename Tags</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody className={wineLists?.length > 0 ? '' : 'empty_row'}>
										{
											wineLists== null ?
												<tr>
													<td colSpan={16}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												wineLists?.length ?
													wineLists?.map((item, i) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
												           <td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>

															{/* <td>{item.restaurantspecialshour?.end_time === "00:00:00" ? "23:59" : moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("HH:mm")}</td> */}
															<td>{item.region?.region_name}</td>
															<td>{item.winemastertype?.wine_type_name}</td>
															{/* <td>-</td>  */}
															<td>{item.winetype?.wine_name || "Multiple Types"}</td>
															<td>{item.winesubtype?.winesubtype_name || "Multiple Wine Names"}</td>
															<td>{item.container?.container_name}</td>
															<td>{item.size?.size_name}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>{item.discount_type === "percentage" ? item.discount_amount : '$' + item.discount_amount}</td>
															<td>{item.item_description}</td>
															<td>{item.Special_tag}</td>
															<td>{item.winetypes}</td>
															<td>{item.winenames}</td>
															<td className="table-action">
																<button className="btn btn-success"
																	onClick={() => {
																		handleUpdate(item.id);
																	}}
																>Update</button>
																<button className="btn btn-danger"
																	onClick={() => {
																		submitDelete(item.id);
																	}}
																>Delete</button>
															</td>
														</tr>
													)) :
													<tr>
														<td colspan="16">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
					<Tab eventKey={2} title="Beer">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
										   <th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Name</th>
											<th>Container type</th>
											<th>Size</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody className={beersLists?.length > 0 ? '' : 'empty_row'}>
										{
											beersLists == null ?
												<tr>
													<td colSpan={12}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												beersLists?.length ?
													beersLists?.map((item, i) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
												        <td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>
															{/* <td>{item.restaurantspecialshour?.start_time === "00" ? "00:00" : moment(item.restaurantspecialshour?.start_time, "HH").format("HH:mm")}</td>
															<td>{item.restaurantspecialshour?.end_time === "00:00:00" ?"23:59" : moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("HH:mm")}</td> */}
															<td>{item.drinkname?.drink_name || 'Multiple Beers'}</td>
															<td>{item.container?.container_name}</td>
															<td>{item.size?.size_name}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>{item.discount_type === "percentage" ? item.discount_amount : '$' + item.discount_amount}</td>
															<td>{item.item_description}</td>
															<td>{item.Special_tag}</td>
															<td>{item.Standard_Tags}</td>
															<td className="table-action">
																<button className="btn btn-success"
																	onClick={() => {
																		handleUpdate(item.id);
																	}}
																>Update</button>
																<button className="btn btn-danger"
																	onClick={() => {
																		submitDelete(item.id);
																	}}
																>Delete</button>
															</td>
														</tr>
													)) :
													<tr>
														<td colspan="12">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
					<Tab eventKey={3} title="Cider">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
										<th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Name</th>
											<th>Container Type</th>
											<th>Size</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody className={ciderLists?.length > 0 ? '' : 'empty_row'}>
										{
											ciderLists == null ?
												<tr>
													<td colSpan={12}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												ciderLists?.length ?
													ciderLists?.map((item, i) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
												<td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>
															{/* <td>{item.restaurantspecialshour?.start_time === "00:00:00" ? "00:00" : moment(item.restaurantspecialshour?.start_time, "HH:mm:ss").format("HH:mm")}</td>
															<td>{item.restaurantspecialshour?.end_time === "00:00:00" ? "23:59" : moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("HH:mm")}</td> */}
															<td>{item.drinkname?.drink_name || 'Multiple Ciders'}</td>
															<td>{item.container?.container_name}</td>
															<td>{item.size?.size_name}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>{item.discount_type === "percentage" ? item.discount_amount : '$' + item.discount_amount}</td>
															<td>{item.item_description}</td>
															<td>{item.Special_tag}</td>
															<td>{item.Standard_Tags}</td>
															<td className="table-action">
																<button className="btn btn-success"
																	onClick={() => {
																		handleUpdate(item.id);
																	}}
																>Update</button>
																<button className="btn btn-danger"
																	onClick={() => {
																		submitDelete(item.id);
																	}}
																>Delete</button>
															</td>
														</tr>
													)) :
													<tr>
														<td colspan="12">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
					<Tab eventKey={4} title="Other">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
										<th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Name</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody className={otherDrinkList?.length > 0 ? '' : 'empty_row'}>
										{
											otherDrinkList == null ?
												<tr>
													<td colSpan={12}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												otherDrinkList?.length ?
													otherDrinkList?.map((item, i) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
												<td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>
															{/* <td>{item.restaurantspecialshour?.start_time === "00:00:00" ? "00:00" : moment(item.restaurantspecialshour?.start_time, "HH:mm:ss").format("HH:mm")}</td>
															<td>{item.restaurantspecialshour?.end_time === "00:00:00" ? "23:59" : moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("HH:mm")}</td> */}
															<td>{item?.drinkname?.drink_name || 'Multiple Others'}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>{item.discount_type === "percentage" ? item.discount_amount : '$' + item.discount_amount}</td>
															<td>{item.item_description}</td>
															<td>{item.Special_tag}</td>
															<td>{item.Standard_Tags}</td>
															<td className="table-action">
																<button className="btn btn-success"
																	onClick={() => {
																		handleUpdate(item.id);
																	}}
																>Update</button>
																<button className="btn btn-danger"
																	onClick={() => {
																		submitDelete(item.id);
																	}}
																>Delete</button>
															</td>
														</tr>
													)) :
													<tr>
														<td colspan="12">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
				</Tabs>: <div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
				}
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<div className='filter-title'>
									<h4>Filters</h4>
									<button className='btn btn-link' onClick={
										resetFilters}>All Records</button>
								</div>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input
											value={appliedFilters.search}
											onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
											type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">Start Time</label>
											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input value={appliedFilters.start_time} onChange={
												(e) => {
													console.log(e.target.value,'kjk')
													setAppliedFilters({
												...appliedFilters,
												start_time: (e.target.value)
												
											})}} type="time" className="form-control" placeholder="Start Time" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">End Time</label>
											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input value={appliedFilters.end_time} onChange={(e) => setAppliedFilters({
												...appliedFilters,
												end_time: e.target.value
											})} type="time" className="form-control" placeholder="End Time" />
										</div>
									</div>
								</div>
								<div className='row mb-4'>
									<div className='col-md-6'>
										<div className="form-group mb-4">
											<label className="form-label">Day</label>
											<select
												value={appliedFilters.day_id}
												className="form-control"
												name="foodCat"
												onChange={(e) => setAppliedFilters({ ...appliedFilters, day_id: e.target.value })}
											>
												<option disabled value=''>Select</option>
												{
													days?.length ?
														days?.map((day, i) => (
															<option value={day.id}>
																{day.day_name}
															</option>
														)) :
														<></>
												}
											</select>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='form-group mb-4'>
											<label className="form-label">Price</label>
											<select
												value={appliedFilters.discount_amount}
												className="form-control"
												name="foodCat"
												onChange={(e) => setAppliedFilters({ ...appliedFilters, discount_amount: e.target.value })}
											>
												<option disabled value=''>Select</option>
												{
													dropDownOption()?.length ?
														dropDownOption()?.map((price, i) => (
															<option value={price.value}>
																{price.value}
															</option>
														)) :
														<></>
												}
											</select>
										</div>
									</div>
								</div>
								{
									tabKey !== '4' ?
										<div className='row'>
											<div className='col-md-6'>
												<div className="form-group mb-4">
													<label className="form-label">Container Type</label>
													<select
														value={appliedFilters.container_type_id}
														className="form-control"
														name="foodCat"
														onChange={(e) => setAppliedFilters({ ...appliedFilters, container_type_id: e.target.value })}
													>
														<option disabled value=''>Select</option>
														{
																containers?.length ?
																containers?.filter(item=>item.drink_type_id == tabKey
																).map((container, i) => (
																	<option key={i} value={container.id}>
																		{container.container_name}
																	</option>
																)) :
																<></>
														}
													</select>
												</div>
											</div>
											<div className='col-md-6'>
												<div className="form-group mb-4">
													<label className="form-label">Size</label>
													<select
														value={appliedFilters.size_id}
														className="form-control"
														name="foodCat"
														onChange={(e) => setAppliedFilters({ ...appliedFilters, size_id: e.target.value })}
													>
														<option disabled value=''>Select</option>
														{
															sizes.length ?
															sizes?.filter(item => item.container_id == appliedFilters.container_type_id && item.Drink_type_id == tabKey)?.map((size, i) => (
																	<option key={i} value={size.id}>
																		{size.size_name}
																	</option>
																)) :
																<></>
														}
													</select>
												</div>
											</div>
										</div> : <></>
								}
								{
									tabKey === '1' ?
										<div className='row'>
											<div className='col-md-6'>
												<div className="form-group mb-4">
													<label className="form-label">Red/White</label>
													<select
														value={appliedFilters.wine_type_id}
														className="form-control"
														name="foodCat"
														onChange={(e) => setAppliedFilters({ ...appliedFilters, wine_type_id: e.target.value })}
													>
														<option disabled value=''>Select</option>
														{
															wineTypes?.length ?
																wineTypes?.map((wine, i) => (
																	<option key={i} value={wine.id}>
																		{wine.wine_name}
																	</option>
																)) :
																<></>
														}
													</select>
												</div>
											</div>
											<div className='col-md-6'>
												<div className="form-group mb-4">
													<label className="form-label">Wine Type</label>
													<select
														value={appliedFilters.wine_subtype_id}
														className="form-control"
														name="foodCat"
														onChange={(e) => setAppliedFilters({ ...appliedFilters, wine_subtype_id: e.target.value })}
													>
														<option disabled value=''>Select</option>
														{
															wineSubTypes?.length ?
																wineSubTypes?.map((wineType, i) => (
																	<option key={i} value={wineType.id}>
																		{wineType.winesubtype_name}
																	</option>
																)) :
																<></>
														}
													</select>
												</div>
											</div>
										</div> : <></>
								}
								<div className='row'>
									<div className='col-md-6'>
										<div className="form-group mb-4">
											<label className="form-label">Discount Type</label>
											<select
												className="form-control"
												name="foodCat"
												value={appliedFilters.discount_type}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, discount_type: e.target.value })}
											>
												<option value="" disabled>Select</option>
												<option value="percentage">Percentage</option>
												<option value="price">Price</option>
												<option value="amount">Amount Off</option>
											</select>
										</div>
									</div>
									{
										tabKey === '1' ?
											<div className='col-md-6'>
												<div className="form-group mb-4">
													<label className="form-label">Regions</label>
													<select
														value={appliedFilters.region_id}
														className="form-control"
														name="foodCat"
														onChange={(e) => setAppliedFilters({ ...appliedFilters, region_id: e.target.value })}
													>
														<option disabled value=''>Select</option>
														{
															regions?.length ?
																regions?.map((region, i) => (
																	<option key={i} value={region.id}>
																		{region.region_name}
																	</option>
																)) :
																<></>
														}
													</select>
												</div>
											</div> : <></>
									}
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={() => {
									appliedFilters.pageno=1;
									getDrinkList({
									tabKey,
									filters: appliedFilters
								});setWineList(null);setBeerList(null);setCiderList(null);setOtherDrinkList(null)}}disabled={dataLoading}> 
								{
								  dataLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Apply Filter'
			  
								}</button>
							</div>
						</div>
					</div>
				</> :
				lastPage > 1 ?
					<>

						{<ul className="pagination justify-content-center">
							<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
								<button className="page-link" onClick={previousPage}>
									Previous
								</button>
							</li>
							{Array.from({ length: lastPage }).map((_, index) => (
								<li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
									<button className="page-link" onClick={() => updateData(index + 1)}>
										{index + 1}
									</button>
								</li>
							))}
							<li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
								<button className="page-link" onClick={nextPage}>
									Next
								</button>
							</li>
						</ul>
						}
					</> : <></>
			}
		</>
	);
};

export default HappyHourDrink;